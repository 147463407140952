import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { DatePicker, Button } from '@class101/ui';
import en from '@class101/ui/formInputs/DatePicker/locales/en_US';
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "datepicker"
    }}>{`DatePicker`}</h1>
    <p>{`날짜를 선택할 수 있고 범위를 지정할 수 있는 DatePicker입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <blockquote>
      <p parentName="blockquote">{`모든 Props는 Optional입니다.`}</p>
    </blockquote>
    <Props of={DatePicker} mdxType="Props" />
    <h2 {...{
      "id": "datepicker-1"
    }}>{`DatePicker`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Docs 페이지에서는 DatePicker가 숨겨져서 `}<inlineCode parentName="p">{`alwaysShow`}</inlineCode>{`를 사용하였습니다. 기본적으로 DatePicker는 숨겨집니다.`}</p>
    </blockquote>
    <p>{`달력은 기본적으로 날짜 선택과 범위 선택이 있습니다.`}</p>
    <p><inlineCode parentName="p">{`useRange`}</inlineCode>{`로 범위 선택을 할 수 있습니다.
`}<inlineCode parentName="p">{`useTime`}</inlineCode>{`으로 시간 선택을 할 수 있습니다.`}</p>
    <Playground __position={1} __code={'<DatePicker alwaysShow inline />\n<DatePicker alwaysShow useRange inline />\n<DatePicker alwaysShow useRange useTime inline />'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePicker alwaysShow inline mdxType="DatePicker" />
  <DatePicker alwaysShow useRange inline mdxType="DatePicker" />
  <DatePicker alwaysShow useRange useTime inline mdxType="DatePicker" />
    </Playground>
    <h2 {...{
      "id": "change-event--default-value"
    }}>{`change Event & default Value`}</h2>
    <p><strong parentName="p"><inlineCode parentName="strong">{`new Date()`}</inlineCode>{`의 month는 0부터 11까지입니다.`}</strong></p>
    <ul>
      <li parentName="ul">{`날짜 선택은 `}<inlineCode parentName="li">{`onChange`}</inlineCode>{`와 `}<inlineCode parentName="li">{`value`}</inlineCode>{`를 사용합니다.`}</li>
      <li parentName="ul">{`범위 선택은 `}<inlineCode parentName="li">{`onChangeRange`}</inlineCode>{`와 `}<inlineCode parentName="li">{`rangeValue`}</inlineCode>{`를 사용합니다.`}</li>
    </ul>
    <Playground __position={2} __code={'<DatePicker\n  value={new Date(2020, 0, 1)}\n  onChange={date => {\n    alert(\'Changed \' + date.toLocaleString())\n  }}\n  alwaysShow\n  inline\n/>\n<DatePicker\n  rangeValue={{\n    start: new Date(2020, 0, 1),\n    end: new Date(2020, 0, 15),\n  }}\n  onChangeRange={dateRange => {\n    alert(\n      \'Changed \' +\n        dateRange.start.toLocaleString() +\n        \' > \' +\n        dateRange.end.toLocaleString(),\n    )\n  }}\n  alwaysShow\n  useRange\n  inline\n/>'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePicker value={new Date(2020, 0, 1)} onChange={date => {
        alert('Changed ' + date.toLocaleString());
      }} alwaysShow inline mdxType="DatePicker" />
  <DatePicker rangeValue={{
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 15)
      }} onChangeRange={dateRange => {
        alert('Changed ' + dateRange.start.toLocaleString() + ' > ' + dateRange.end.toLocaleString());
      }} alwaysShow useRange inline mdxType="DatePicker" />
    </Playground>
    <h2 {...{
      "id": "prevent-changes"
    }}>{`Prevent Changes`}</h2>
    <p>{`onChange 이벤트 내에서 `}<inlineCode parentName="p">{`return false`}</inlineCode>{`를 하면 날짜 선택이 막힙니다.`}</p>
    <Playground __position={3} __code={'<DatePicker\n  onChange={date => {\n    if (date.getDate() > 20) return false\n  }}\n  alwaysShow\n  inline\n/>\n<DatePicker\n  onChange={date => {\n    if (date.getHours() > 20) return false\n  }}\n  alwaysShow\n  useTime\n  inline\n/>\n<DatePicker\n  onChangeRange={({ start, end }) => {\n    if (start.getDate() < 5) return false\n    if (end.getDate() > 20) return false\n  }}\n  alwaysShow\n  useRange\n  inline\n/>'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePicker onChange={date => {
        if (date.getDate() > 20) return false;
      }} alwaysShow inline mdxType="DatePicker" />
  <DatePicker onChange={date => {
        if (date.getHours() > 20) return false;
      }} alwaysShow useTime inline mdxType="DatePicker" />
  <DatePicker onChangeRange={({
        start,
        end
      }) => {
        if (start.getDate() < 5) return false;
        if (end.getDate() > 20) return false;
      }} alwaysShow useRange inline mdxType="DatePicker" />
    </Playground>
    <h2 {...{
      "id": "alwaysshow"
    }}>{`alwaysShow`}</h2>
    <p><inlineCode parentName="p">{`alwaysShow`}</inlineCode>{`를 설정하면 항상 달력 선택창이 보이게 할 수 있습니다.`}</p>
    <Playground __position={4} __code={'<DatePicker inline style={{ verticalAlign: \'top\' }} />\n<DatePicker alwaysShow inline />'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePicker inline style={{
        verticalAlign: 'top'
      }} mdxType="DatePicker" />
  <DatePicker alwaysShow inline mdxType="DatePicker" />
    </Playground>
    <h2 {...{
      "id": "locale"
    }}>{`Locale`}</h2>
    <p><inlineCode parentName="p">{`locale`}</inlineCode>{`을 통해 언어를 설정할 수 있습니다.
현재 영어와 한국어가 있습니다. (기본은 한국어입니다.)`}</p>
    <h3 {...{
      "id": "import"
    }}>{`Import`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import en from '@class101/ui/formInputs/DatePicker/locales/en_US';
import ko from '@class101/ui/formInputs/DatePicker/locales/ko_KR';
`}</code></pre>
    <Playground __position={5} __code={'<DatePicker locale={en} alwaysShow />'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePicker locale={en} alwaysShow mdxType="DatePicker" />
    </Playground>
    <h2 {...{
      "id": "mindate--maxdate"
    }}>{`MinDate & MaxDate`}</h2>
    <p><inlineCode parentName="p">{`minDate`}</inlineCode>{`과 `}<inlineCode parentName="p">{`maxDate`}</inlineCode>{`를 통해 최소, 최대기간을 설정할 수 있습니다.`}</p>
    <Playground __position={6} __code={'<DatePicker\n  minDate={new Date(2019, 0, 1)}\n  value={new Date(2019, 3, 1)}\n  maxDate={new Date(2019, 11, 31, 13, 23, 0)}\n  alwaysShow\n  useTime\n  inline\n/>\n<DatePicker\n  minDate={new Date(2020, 4, 1)}\n  maxDate={new Date(2020, 6, 30)}\n  alwaysShow\n  useRange\n  inline\n/>'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePicker minDate={new Date(2019, 0, 1)} value={new Date(2019, 3, 1)} maxDate={new Date(2019, 11, 31, 13, 23, 0)} alwaysShow useTime inline mdxType="DatePicker" />
  <DatePicker minDate={new Date(2020, 4, 1)} maxDate={new Date(2020, 6, 30)} alwaysShow useRange inline mdxType="DatePicker" />
    </Playground>
    <h2 {...{
      "id": "highlightweekend"
    }}>{`highlightWeekEnd`}</h2>
    <p><inlineCode parentName="p">{`highlightWeekEnd`}</inlineCode>{`를 통해 주말을 하이라이트 색으로 표시할지 선택할 수 있습니다.`}</p>
    <Playground __position={7} __code={'<DatePicker alwaysShow inline />\n<DatePicker highlightWeekEnd={false} alwaysShow inline />'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePicker alwaysShow inline mdxType="DatePicker" />
  <DatePicker highlightWeekEnd={false} alwaysShow inline mdxType="DatePicker" />
    </Playground>
    <h2 {...{
      "id": "adjustinputwidth"
    }}>{`adjustInputWidth`}</h2>
    <p><inlineCode parentName="p">{`adjustInputWidth`}</inlineCode>{`를 통해 input의 너비를 DatePicker와 똑같이 맞출지 정할 수 있습니다.`}</p>
    <p>{`기본값은 `}<inlineCode parentName="p">{`true`}</inlineCode>{`입니다.`}</p>
    <Playground __position={8} __code={'<DatePicker\n  adjustInputWidth={false}\n  style={{ verticalAlign: \'top\' }}\n  inline\n/>\n<DatePicker alwaysShow inline />'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <DatePicker adjustInputWidth={false} style={{
        verticalAlign: 'top'
      }} inline mdxType="DatePicker" />
  <DatePicker alwaysShow inline mdxType="DatePicker" />
    </Playground>
    <h2 {...{
      "id": "외부에서-state로-관리하기"
    }}>{`외부에서 state로 관리하기`}</h2>
    <Playground __position={9} __code={'() => {\n  const [range, setRange] = React.useState({\n    start: new Date(2020, 0, 1),\n    end: new Date(2020, 0, 15),\n  })\n  return (\n    <div>\n      <Button\n        onClick={() => {\n          setRange({\n            start: new Date(2020, 3, 1),\n            end: new Date(2020, 3, 30),\n          })\n        }}\n      >\n        4월\n      </Button>\n      <Button\n        onClick={() => {\n          setRange({\n            start: new Date(2020, 4, 1),\n            end: new Date(2020, 4, 31),\n          })\n        }}\n      >\n        5월\n      </Button>\n      <Button\n        onClick={() => {\n          setRange({\n            start: new Date(2020, 5, 1),\n            end: new Date(2020, 5, 30),\n          })\n        }}\n      >\n        6월\n      </Button>\n      <DatePicker\n        rangeValue={range}\n        onChangeRange={() => setRange(range)}\n        alwaysShow\n        useRange\n        inline\n      />\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      DatePicker,
      Button,
      en,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [range, setRange] = React.useState({
          start: new Date(2020, 0, 1),
          end: new Date(2020, 0, 15)
        });
        return <div>
        <Button onClick={() => {
            setRange({
              start: new Date(2020, 3, 1),
              end: new Date(2020, 3, 30)
            });
          }} mdxType="Button">4월</Button>
        <Button onClick={() => {
            setRange({
              start: new Date(2020, 4, 1),
              end: new Date(2020, 4, 31)
            });
          }} mdxType="Button">5월</Button>
        <Button onClick={() => {
            setRange({
              start: new Date(2020, 5, 1),
              end: new Date(2020, 5, 30)
            });
          }} mdxType="Button">6월</Button>
        <DatePicker rangeValue={range} onChangeRange={() => setRange(range)} alwaysShow useRange inline mdxType="DatePicker" />
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      